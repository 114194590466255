import React from 'react'

function Banner() {
  return (
    <div
      data-testid="banner-1"
      data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500"
      style={{ height: '50vh', position: 'relative' }}
    >
      <div
        style={{
          position: 'fixed',
          height: '50vh',
          width: '100%',
          zIndex: '-1',
        }}
      >
        <img
          className="banner"
          src="/images/cameron.jpg"
          style={{ objectFit: 'cover', zIndex: '-1' }}
        />
      </div>

      <div className="uk-overlay uk-light uk-position-bottom">
        <div className="uk-container uk-container-fluid">
          <h4 className="banner-texts texts">
            <b>Adaptive Computer Solutions</b>
          </h4>
          <div className='uk-margin-bottom uk-text-left uk-width-1-1 sub-title'>
            <div><span className="entities">&lt;</span>We code dreams:</div>
            <div> it's possible<span className="entities">/&gt;</span></div>

          </div>
          <br />
        </div>
      </div>
    </div>
  )
}

export default Banner
