import React from 'react'
import PageTitle from '../../PageTitle/PageTitle'
import TestimonialsCard from '../ClientTestimonials/TestimonialsCard'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'
import './Testimonials.css'

function Testimonials() {
  PageTitle('Testimonials :: ACS :: World Class Software"')
  return (
    <>
      <div data-testid="Testimonials-1" className="bg-col">
        <Navbar />
        <div className="uk-container address uk-margin">
          <h2 className="testimonials-text">
            <b>Testimonials</b>
          </h2>
          <h4 className="delighted-texts">
            What some of our delighted customers say about us
          </h4>
          <div
            className="uk-grid uk-grid-match uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-2@s"
            data-uk-scrollspy="target: > div; cls: uk-animation-fade-small; delay: 500"
          >
            <div
              data-uk-scrollspy-class="uk-animation-slide-top-small"
            >
              <TestimonialsCard
                cardImage="/images/user4.png"
                cardTitle="Asiedu Samuel"
                cardPassage="O“ The level of sophistication of your solution, the short time within which it was developed and its impact on our bottom-line, is quite impressive"
              />
            </div>
            <div
              data-uk-scrollspy-class="uk-animation-slide-bottom-small"
            >
              <TestimonialsCard
                cardImage="/images/user1.png"
                cardTitle="Alfred Neequaye"
                cardPassage="“Your two developers assigned to us: A. and S. have been of tremendous help in the restoration of our customer’s trust in our bundles and other products and services.”"
              />
            </div>
            <div
              data-uk-scrollspy-class="uk-animation-slide-top-small"
            >
              <TestimonialsCard
                cardImage="/images/user2.png"
                cardTitle="Floxy Korkor Nartey"
                cardPassage="“The work output of your engineer E.O must be commended for its timeliness and quality”"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Testimonials
