import React from 'react'
import ClientCard from '../ClientCard/ClientCard'
import Slider from 'react-slick'

function OurClients() {
  const slickSetting = {
    arrows: true,
    dots: false,
    speed: 1200,
    slidesToShow: 6,
    //centerMode: true,
    autoplay: true,
    autoplaySpeed: 900,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 712,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="bg-col">
      <div className="uk-container">
        <div className="uk-grid">
          <div id="OurClients" className="uk-width-1-3@l uk-width-1-1@s">
            <h3 className="address texts">Our Clients</h3>
            <hr className="uk-divider-small active"></hr>
            <p className="texts">Brands that trust us</p>
          </div>
        </div>
        <Slider {...slickSetting}>
          <ClientCard image="/images/marina.png" />
          <ClientCard image="/images/mercedes.png" />
          <ClientCard image="/images/ericsson.png" />
          <ClientCard image="/images/bidvest.png" />
          <ClientCard image="/images/mtn_rounded.png" />
          <ClientCard image="/images/peugeot.png" />
          <ClientCard image="/images/nissan.png" />
          <ClientCard image="/images/download.png" />
          <ClientCard image="/images/icslogo.png" />
          <ClientCard image="/images/starbow.png" />
          <ClientCard image="/images/yamaha.png" />
        </Slider>

        <hr className="horizontal-line" />
      </div>
    </div>
  )
}

export default OurClients
