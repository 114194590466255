import React from 'react'

export default function Step({ step = '0', text = '', direction = '' }) {
  return (
    <div data-testid="Step-1" className={`service-step ${direction}`}>
      <span>{step}</span>
      <span></span>
      <span>{text}</span>
    </div>
  )
}
