import React, { useState } from 'react'
import Navbar from '../Homepage/Navbar'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../Homepage/Footer'
import PageTitle from '../../PageTitle/PageTitle'
import CareersTexts from './CareersTexts'
import CareersCard from './CareersCard'
import dummy from '../../dummy'
import { nameSlug } from '../../utlility'

function Careers() {
  PageTitle('Careers :: ACS :: World Class Software')
  const path = useLocation()
  const [careers] = useState(dummy.careers)

  return (
    <>
      <div data-testid="careers-1" className="bg-col">
        <Navbar />
        <CareersTexts
          mainHeader="Find Your Next Job With Us"
          mainParagraph="Are you smart, skill driven and want to make a difference in your field of work? It all starts right here. Send us your resume we will contact you when a position is available"
          subHeader="Job Openings"
        />
        <div className="uk-container">
          <div
            className="uk-grid uk-grid-match"
            data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .uk-card; delay: 300; repeat: false"
          >
            {careers.map(
              ({ careerId, careerTitle, careerContent, careerImg }, index) => (
                <div
                  className="uk-width-1-3@l uk-width-1-3@m uk-width-1-3@s uk-margin-bottom"
                  key={index}
                >
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`${path.pathname}/${nameSlug(careerTitle)}`}
                  >
                    <CareersCard
                      id={careerId}
                      careerCardImage={careerImg}
                      careerCardTitle={careerTitle}
                      careerCardPassage={careerContent}
                    />
                  </Link>
                </div>
              ),
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Careers
