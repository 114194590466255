import SectionLink from "../SectionLink/SectionLink";
import { NavLink } from 'react-router-dom'
import UIkit from 'uikit'

function SideNav() {
    const hideSidebar = () => {
        UIkit.offcanvas('#side-navigation-offcanvas').hide();
    }
    return (
        <div id="side-navigation-offcanvas" data-uk-offcanvas=" flip: true; overlay: true">
            <div className="uk-offcanvas-bar">

                <button className="uk-offcanvas-close" type="button" data-uk-close></button>

                <h3>ACS - Menu</h3>
                <ul className="uk-list">
                    <li onClick={hideSidebar} className="nav-item">
                        <SectionLink section="About">About Us</SectionLink>
                    </li>
                    <li onClick={hideSidebar} className="nav-item">
                        <SectionLink section="PnS">Product & Services</SectionLink>
                    </li>
                    <li onClick={hideSidebar} className="nav-item">
                        <SectionLink section="OurClients">Client</SectionLink>
                    </li>
                    <li onClick={hideSidebar} className="nav-item">
                        <SectionLink section="Contact">Contact</SectionLink>
                    </li>
                    <li onClick={hideSidebar}>
                        <NavLink to="/Portfolio">Portfolio</NavLink>
                    </li>
                    <li onClick={hideSidebar}>
                        <NavLink to="/Careers">Career</NavLink>
                    </li>
                    <li onClick={hideSidebar}>
                        <NavLink to="/Blog">Blog</NavLink>
                    </li>
                    <li onClick={hideSidebar}>
                        <a target={'_blank'} href="https://www.adaptivehris.com/">
                            Adaptive HRIS
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideNav;