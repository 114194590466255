import React, { useState, useEffect } from 'react'
import $ from "jquery"
import PageTitle from '../../PageTitle/PageTitle'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'
import { useParams, useLocation } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '../Homepage/Homepage.css'
import dummy from '../../dummy'
import { nameSlug } from '../../utlility'
import config from '../../config'

function JobTitle() {
  PageTitle('Job Title :: ACS :: World Class Software')

  const initialValues = {
    type: '',
    signUpForAlerts: false,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    highestQualification: '',
    uploadCv: '',
    coverLetter: '',
  }

  const [careers] = useState(dummy.careers)
  const [singleBlog, setSingleBlog] = useState({})
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [formLoading, setFormLoading] = useState(false)
  const [formMessage, setFormMessage] = useState()

  const path = useLocation()
  const { id } = useParams()

  const editorConfiguration = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
    ],
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const errorMessage = (mssage) => {
    return (
      <div
        className="uk-animation-toggle uk-animation-slide-bottom-small uk-alert-danger custom-alert"
        data-uk-alert
      >
        <a className="uk-alert-close" data-uk-close></a>
        <p>{mssage}</p>
      </div>
    )
  }
  const successMessage = (mssage) => {
    return (
      <div
        className="uk-animation-toggle uk-animation-slide-bottom-small uk-alert-success custom-alert"
        data-uk-alert
      >
        <a className="uk-alert-close" data-uk-close></a>
        <p>{mssage}</p>
      </div>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    var errors = validate(formValues)
    setFormErrors(errors)
    if (Object.keys(errors).length === 0) {
      sendFormRequest()
    }
  }

  useEffect(async () => {
    setSingleBlog(
      careers.filter((data) => nameSlug(data.careerTitle) == nameSlug(id)),
    )
  }, [])

  const validate = (values) => {
    var input = document.getElementById('cvUpload')
    const errors = {}
    const phoneNumberRegex = /^(233|0)?(\d{9})$/i
    if (!values.firstName) {
      errors.firstName = errorMessage('First Name field is required')
    } else if (values.firstName.length < 2) {
      errors.firstName = errorMessage("First Name can't be 3 characters long")
    }
    if (!values.lastName) {
      errors.lastName = errorMessage('Last Name field is required')
    } else if (values.lastName.length < 2) {
      errors.lastName = errorMessage("Last Name can't be 3 characters long")
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = errorMessage('Date of birth field is required')
    }

    if (!values.highestQualification) {
      errors.highestQualification = errorMessage(
        'Qualification field is required',
      )
    }

    if (!values.type) {
      errors.type = errorMessage('Application type field is required')
    }

    if (input.files.length == 0) {
      errors.uploadCv = errorMessage('Attach a CV')
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = errorMessage('Phone number field is required')
    } else if (!phoneNumberRegex.test(values.phoneNumber)) {
      errors.phoneNumber = errorMessage('Enter a valid phone number')
    }

    if (!values.email) {
      errors.email = errorMessage('Email field is required')
    } 

    if (!values.coverLetter) {
      errors.coverLetter = errorMessage('Cover letter is required')
    }
    return errors
  }



  const sendFormRequest = () => {
    var input = document.getElementById('cvUpload')
    setFormLoading(true)
    var data = new FormData()
    data.append('File', input.files[0])
    data.append('JobTitle', singleBlog[0]?.careerTitle)
    data.append('JobCategory', '')
    data.append('Type', formValues.type)
    data.append('FirstName', formValues.firstName)
    data.append('LastName', formValues.lastName)
    data.append('EmailAddress', formValues.email)
    data.append('Date', formValues.dateOfBirth)
    data.append('PhoneNumber', formValues.phoneNumber)
    data.append('HighestQualification', formValues.highestQualification)
    data.append('Signup', formValues.signUpForAlerts)
    data.append('CoverLetter', formValues.coverLetter)

   document.getElementById("careerForm").reset();
   
  $('.ck-editor__editable').html("");
    
    formValues.email = ""
    formValues.dateOfBirth = ""
    formValues.phoneNumber = ""
    
    setFormLoading(false)
    setFormMessage(
      successMessage(
        "Thank you for submitting your detials. We welcome fresh ideas and talents. Don't let it end here, go through our portfolio and services. Our team will get in touch when you are shorlisted for a position.",
      ),
    )


    CKEditor.instances.theInstance.setData( '', function() { this.updateElement(); } )
    
    fetch(config.apiBaseUrl + '/api/Forms/Submitresume', {
      method: 'POST',
      body: data,
    })
      .then((res) => {
        document.getElementById("careerForm").reset();
        formValues.email = ""
        formValues.dateOfBirth = ""
        formValues.phoneNumber = ""
        formValues.coverLetter = " "
        setFormErrors({})
        setFormValues(initialValues)
        setFormLoading(false)
        setFormMessage(
          successMessage(
            "Thank you for submitting your detials. We welcome fresh ideas and talents. Don't let it end here, go through our portfolio and services. Our team will get in touch when you are shorlisted for a position.",
          ),
        )
      })
      .catch((err) => {
        {console.log("erro" + err)}
        setFormMessage(
          errorMessage('An error occured. Please try again later.'),
        )
        setFormErrors({})
        setFormLoading(false)
      })
  }

  return (
    <>
      <div className="bg-col">
        <Navbar />
        
        <div className="uk-container">
          <h3 className="texts entities-1 address">
            <b>{singleBlog[0]?.careerTitle}</b>
          </h3>
          <h4>
            <b>Description</b>
          </h4>
          
          <h5 className="texts">
            <b>{singleBlog[0]?.careerContent}</b>
          </h5>

          <p className="texts">{singleBlog[0]?.careerDetailsPassage}</p>
          <p className="texts">{singleBlog[0]?.careerSpecifications}</p>
          <h4 className="texts entities-1 address">
            <b>Apply</b>
          </h4>
          <form id='careerForm' onSubmit={handleSubmit}>
            <div className="uk-grid">
              <div className="uk-width-1-1 uk-margin">{formMessage}</div>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-margin-bottom">
                {formErrors.firstName}
                <input
                  className="uk-input"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleChange}
                />
              </div>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-margin-bottom">
                {formErrors.lastName}
                <input
                  className="uk-input"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleChange}
                />
              </div>
              <div className="uk-width-1-3@l uk-position-relative uk-width-1-1@s uk-margin-bottom">
                {formErrors.dateOfBirth}
                <input
                  className="uk-input"
                  type="date"
                  name="dateOfBirth"
                  placeholder="Date of Birth"
                  value={formValues.dateOfBirth}
                  onChange={handleChange}
                />
                <span className="label-secondary">Date of Birth</span>
              </div>
            </div>
            <div className="uk-grid">
              <div className="uk-width-1-3@l uk-width-1-1@s uk-margin-bottom">
                {formErrors.email}
                <input
                  className="uk-input"
                  type="email"
                  placeholder="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                />
              </div>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-margin-bottom">
                {formErrors.phoneNumber}
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={formValues.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-margin-bottom">
                <div className="uk-margin uk-position-relative">
                  {formErrors.highestQualification}
                  <select
                    name="highestQualification"
                    onChange={handleChange}
                    className="uk-select"
                  >
                    <option>Select</option>
                    <option value="Wassce">Wassce</option>
                    <option value="HND">HND</option>
                    <option value="Degree">Degree</option>
                    <option value="Masters">Masters</option>
                  </select>
                  <span className="label-secondary">Highest Qualification</span>
                </div>
              </div>
            </div>
            <div className="uk-grid uk-margin-bottom">
              <div className="uk-width-1-3@l uk-width-1-1@s">
                {formErrors.uploadCv}
                <label className="upload-texts">Upload CV </label>
                <input
                  className="upload"
                  id="cvUpload"
                  type="file"
                  data-multiple
                />
              </div>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-text-left">
                <label>Sign up for future job alerts</label>
                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                  <label>
                    <input
                      className="uk-radio"
                      name="signUpForAlerts"
                      value={true}
                      onChange={handleChange}
                      type="radio"
                    />
                    Sign up
                  </label>
                  <label>
                    <input
                      className="uk-radio"
                      name="signUpForAlerts"
                      value={false}
                      onChange={handleChange}
                      type="radio"
                      checked
                    />{' '}
                    No thanks
                  </label>
                </div>
              </div>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-margin-bottom">
                <div className="uk-margin uk-position-relative">
                  {formErrors.type}
                  <select
                    name="type"
                    onChange={handleChange}
                    className="uk-select"
                  >
                    <option>Select</option>
                    <option value="Intern">Intern</option>
                    <option value="Contract employee">Contract employee</option>
                    <option value="National service person">
                      National service person
                    </option>
                    <option value="Permanent fulltime employee">
                      Permanent fulltime employee
                    </option>
                    <option value="Permanent parttime employee">
                      Permanent parttime employee
                    </option>
                    <option value="Temporary fulltime employee">
                      Temporary fulltime employee
                    </option>
                    <option value="Temporary parttime employee">
                      Temporary parttime employee
                    </option>
                  </select>
                  <span className="label-secondary">Application Type</span>
                </div>
              </div>
            </div>

            <div className="uk-grid uk-margin-large-bottom">
              <div className="uk-width-1-1 uk-margin-bottom">
                <h5 className="texts">Cover letter</h5>
                {formErrors.coverLetter}
                <CKEditor
                  className="ck-field"
                  id="ckeditor"
                  config={editorConfiguration}
                  value={formValues.coverLetter}
                  editor={ClassicEditor}
                 
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setFormValues({ ...formValues, ['coverLetter']: data })
                  }}
                />
              </div>
              <div className="uk-width-1-3 uk-text-left">
                <button
                  className="uk-button uk-button-danger btn"
                  {...(formLoading && { disabled: true })}
                >
                  {formLoading ? (
                    <>
                      <span
                        className="uk-margin-small-right"
                        data-uk-spinner="ratio:.6"
                      ></span>
                      <span>Sending...</span>
                    </>
                  ) : (
                    <span>Apply</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default JobTitle
