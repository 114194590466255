import React from 'react'
import './Homepage.css'
import { NavLink } from 'react-router-dom'
import SectionLink from '../SectionLink/SectionLink'

function Footer() {
  const socialLinks = [
    { linkIcon: 'twitter', linkLocation: 'https://twitter.com/ACS_io' },
    {
      linkIcon: 'facebook',
      linkLocation: 'https://web.facebook.com/adaptiveComputer',
    },
    { linkIcon: 'receiver', linkLocation: 'tel://+233 303 937 079' },
    {
      linkIcon: 'youtube',
      linkLocation: 'https://www.youtube.com/channel/UC5Tu3Zd9_qq_nXn4jbjwSPw',
    },
    {
      linkIcon: 'whatsapp',
      linkLocation: 'https://api.whatsapp.com/send?phone=233303937079',
    },
  ]
  return (
    <div
      data-testid="Footer-1"
      style={{ backgroundColor: 'white', paddingTop: '30px' }}
    >
      <div className="uk-container uk-margin">
        <div className="uk-grid uk-child-width-1-3@l uk-child-width-1-3@m uk-child-width-1-3@s">
          <div className="uk-margin-bottom">
            <h4 className="texts">
              <b>Introduction</b>
            </h4>
            <ul className="list-style texts">
              <li>
                <a className="home-link" href="#" data-uk-totop data-uk-scroll>
                  Home
                </a>
              </li>
              <li className="footer-intro-items nav-items">
                <SectionLink section="About" className="links">
                  About Us
                </SectionLink>
              </li>
              <li className="footer-intro-items nav-items">
                <SectionLink section="PnS" className="links">
                  Our Services
                </SectionLink>
              </li>
              <li className="links">
                <NavLink
                  className="links"
                  to="/Careers"
                  activeClassName="active-link"
                >
                  Career
                </NavLink>
              </li>
              <li>
                <SectionLink className="links" section="ClientsTestimonials">
                  Testimonials
                </SectionLink>
              </li>
            </ul>
          </div>
          <div className="uk-margin-bottom">
            <h4 className="texts helpful-container">
              <b>Helpful Links</b>
            </h4>
            <ul className="list-style texts">
              <NavLink
                to="/TechStack"
                className="links"
                activeClassName="active-link"
              >
                <li>Tech Stack</li>
              </NavLink>
              <NavLink
                to="/ExecutiveSummary"
                className="links"
                activeClassName="active-link"
              >
                <li>Executive Summary</li>
              </NavLink>
              <li>
                <NavLink
                  className="links"
                  to="/ServiceDelivery"
                  activeClassName="active-link"
                >
                  Service Delivery
                </NavLink>
              </li>
              <NavLink
                className="links"
                to="/ValueProposition"
                activeClassName="active-link"
              >
                Value Proposition
              </NavLink>
            </ul>
          </div>
          <div className="uk-margin-bottom">
            <h4 className="texts">
              <b>Other Links</b>
            </h4>
            <ul className="list-style texts">
              <a
                target="_blank"
                className="links"
                href="https://www.adaptivehris.com/"
              >
                <li>Adaptive HRIS</li>
              </a>
              <NavLink
                className="links"
                to="/Resources"
                activeClassName="active-link"
              >
                <li>Resources</li>
              </NavLink>

              <NavLink
                className="links"
                to="/TermsConditions"
                activeClassName="active-link"
              >
                <li>Terms and Conditions</li>
              </NavLink>
              <NavLink
                className="links"
                to="/PrivacyPolicy"
                activeClassName="active-link"
              >
                <li>Privacy Policy</li>
              </NavLink>
            </ul>
          </div>
        </div>
        <hr />
        <div className="uk-grid footer-2">
          <div className="social uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s">
            <ul className="list-style texts">
              <li>
                <img src="/images/logo.png" style={{ width: 100 }} />
              </li>
              <br />
              {socialLinks.map(({ linkIcon, linkLocation }, i) => {
                return (
                  <li key={i} className="social-links">
                    <a target="_blank" href={linkLocation}>
                      <span data-uk-icon={linkIcon}></span>
                    </a>
                  </li>
                )
              })}

              <li>
                <p className="footer-acs-text">
                  @ 2022 Adaptive Computer Solutions
                </p>
              </li>
            </ul>
          </div>

          <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s">
            <h4 className="uk-margin-top texts">Address</h4>
            <ul className="list-style texts">
              <li>
                <a
                  className="loc-address"
                  target="_blank"
                  href="https://maps.google.com/maps?q=5.66660213470459%2C-0.19484350085258484&z=17&hl=en"
                >
                  <span
                    className="entities footer-icons"
                    data-uk-icon="location"
                  ></span>
                  Hse # 4, Jasmine St. Adjacent WW HOMES, Haasto
                </a>
              </li>
              <li>
                <span
                  className="entities footer-icons"
                  data-uk-icon="receiver"
                ></span>
                <a className="loc-address" href="tel://+233 303 937 079">
                  +233 303 937 079
                </a>
                ,
                <a
                  className="loc-address"
                  style={{ paddingLeft: '10px' }}
                  href="tel://+233 595 950 105"
                >
                  Sales: +233 595 950 105
                </a>
              </li>
              <li>
                <a
                  className="loc-address"
                  target="_blank"
                  href="mailto:info@acsint.io"
                >
                  <span
                    className="entities footer-icons"
                    data-uk-icon="mail"
                  ></span>
                  info@acsint.io
                </a>
              </li>
              <li>
                <a
                  className="loc-address"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=233303937079"
                >
                  <span
                    className="entities footer-icons"
                    data-uk-icon="whatsapp"
                  ></span>
                  +233 303 937 079
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
