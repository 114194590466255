import React from 'react'
import './ExecutiveSummary.css'
import Navbar from '../Homepage/Navbar'
import Footer from '../Homepage/Footer'
import PageTitle from '../../PageTitle/PageTitle'

function ExecutiveSummary() {
  PageTitle('ExecutiveSummary :: ACS :: World Class Software')
  return (
    <>
      <div className="bg-col">
        <Navbar />
        <div
          className="uk-position-relative"
          style={{ height: '40vh' }}
          data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500"
        >
          <div
            className="img-scroll"
            data-uk-img
            style={{
              width: '100%',
              height: '300px',
              backgroundImage: 'url(/images/Charles.png)',
              backgroundSize: '100% 100%',
              zIndex: '50',
            }}
          ></div>
        </div>
        <div className="uk-container">
          <h3 className="entities address uk-margin-large-top texts">
            <b>Executive Summary</b>
            <hr className=" active" style={{ marginTop: '5px' }}></hr>
          </h3>
          <div className='uk-margin-large-bottom'>
            <p className="texts">
              As a reliable supplier of technical and project management talent on
              an outsourcing basis to enable corporate customers to achieve their
              projects that involve software development and integration
              components, we are proposing to provide a pool of highly skilled
              software engineering, agile scrum masters, software operations and
              support, quality assurance and other resources that may be necessary
              for businesses within Ghana and beyond to achieve their corporate
              goals. Our pool of resources have experience working in and leading
              agile teams, building and operating highly scalable applications
              from mobile frontend all the way to API and databases.
            </p>
            <p className="texts">
              The provided resources will work together with team members from the
              client to collaboratively deliver the stated goals of each project.
              In relation to the engagement model, our team has experience with
              handling projects delivered via the onshore, offshore, or hybrid
              outsourcing models. Modern application development and delivery
              practices will be observed including code reviews, unit testing,
              static code analysis, integration testing, DevOps and micro-services
              architecture.
            </p>
          </div>

          {/* <h3 className="entities texts">
            <b>Executive Team</b>
            <hr className=" active" style={{ marginTop: '5px' }}></hr>
          </h3>
          <div
            className="uk-grid"
            data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500"
          >
            <div
              className="uk-width-1-4@l uk-margin-bottom uk-width-1-2@m uk-width-1-1@s"
              data-uk-scrollspy-class="uk-animation-slide-top"
            >
              <div className="uk-card uk-card-default uk-inline-clip uk-transition-toggle  exe-card">
                <img src="images/Executive2.png" alt="" />
                <p className="uk-transition-scale-up uk-position-cover exe-hover">
                  As a reliable supplier of technical and project management
                  talent on an outsourcing basis to enable corporate customers
                  to achieve their projects that involve software development
                  and integration
                </p>
                <div className="uk-card-body">
                  <h3 className="uk-card-title">Richard Quest</h3>
                  <hr
                    className="active-executive"
                    style={{ marginTop: '1px' }}
                  ></hr>
                  <p>Managing Director</p>
                  <div className="exe-social-links">
                    <span uk-icon="facebook"></span>
                    <span uk-icon="twitter"></span>
                    <span uk-icon="instagram"></span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="uk-width-1-4@l uk-margin-bottom uk-width-1-2@m uk-width-1-1@s"
              data-uk-scrollspy-class="uk-animation-slide-bottom"
            >
              <div className="uk-card uk-card-default uk-inline-clip uk-transition-toggle  exe-card">
                <img src="images/Executive2.png" alt="" />
                <p className="uk-transition-scale-up uk-position-cover exe-hover">
                  As a reliable supplier of technical and project management
                  talent on an outsourcing basis to enable corporate customers
                  to achieve their projects that involve software development
                  and integration
                </p>
                <div className="uk-card-body">
                  <h3 className="uk-card-title">Richard Quest</h3>
                  <hr
                    className="active-executive"
                    style={{ marginTop: '1px' }}
                  ></hr>
                  <p>Managing Director</p>
                  <div className="exe-social-links">
                    <span uk-icon="facebook"></span>
                    <span uk-icon="twitter"></span>
                    <span uk-icon="instagram"></span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="uk-width-1-4@l uk-margin-bottom uk-width-1-2@m uk-width-1-1@s"
              data-uk-scrollspy-class="uk-animation-slide-top"
            >
              <div className="uk-card uk-card-default uk-inline-clip uk-transition-toggle  exe-card">
                <img src="images/Executive2.png" alt="" />
                <p className="uk-transition-scale-up uk-position-cover exe-hover">
                  As a reliable supplier of technical and project management
                  talent on an outsourcing basis to enable corporate customers
                  to achieve their projects that involve software development
                  and integration
                </p>
                <div className="uk-card-body">
                  <h3 className="uk-card-title">Richard Quest</h3>
                  <hr
                    className="active-executive"
                    style={{ marginTop: '1px' }}
                  ></hr>
                  <p>Managing Director</p>
                  <div className="exe-social-links">
                    <span uk-icon="facebook"></span>
                    <span uk-icon="twitter"></span>
                    <span uk-icon="instagram"></span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="uk-width-1-4@l  uk-width-1-2@m uk-width-1-1@s exe-portfolio"
              data-uk-scrollspy-class="uk-animation-slide-bottom"
            >
              <div className="uk-card uk-card-default uk-inline-clip uk-transition-toggle  exe-card">
                <img src="images/Executive2.png" alt="" />
                <p className="uk-transition-scale-up uk-position-cover exe-hover">
                  As a reliable supplier of technical and project management
                  talent on an outsourcing basis to enable corporate customers
                  to achieve their projects that involve software development
                  and integration
                </p>
                <div className="uk-card-body">
                  <h3 className="uk-card-title">Richard Quest</h3>
                  <hr
                    className="active-executive"
                    style={{ marginTop: '1px' }}
                  ></hr>
                  <p>Managing Director</p>
                  <div className="exe-social-links">
                    <span uk-icon="facebook"></span>
                    <span uk-icon="twitter"></span>
                    <span uk-icon="instagram"></span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ExecutiveSummary
