import React from 'react'
import './client-card.css'

function ClientCard({ image = '/images/mtn.png' }) {
  return (
    <div data-testid="client-card-1" className="client-card">
      <div className="uk-card">
        <div className="client-image">
          <img src={image} />
        </div>
      </div>
    </div>
  )
}

export default ClientCard
