import './App.css'
import React, { lazy, Suspense } from 'react'
import Careers from './Components/Careers/Careers'
import HomePage from './Components/Homepage/HomePage'
// import Blog from './Components/Blog/Blog'
import Portfolio from './Components/Portfolio/Portfolio'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import JobTitle from './Components/Careers/JobTitle'
import Project from './Components/Portfolio/Project'
import ExecutiveSummary from './Components/Executive Summary/ExecutiveSummary'
import ValuePropositon from './Components/ValueProposition/ValuePropositon'
import ServiceDelivery from './Components/ServiceDelivery/ServiceDelivery'
import TechStack from './Components/TechStack/TechStack'
import Testimonials from './Components/Testimonials/Testimonials'
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from './Components/TermsConditions/TermsConditions'
import Resources from './Components/Resources/Resources'
import ScrollToTop from './Components/ScrollToTop'
import BlogDetails from './Components/Blog/BlogDetails'
import SideNav from './Components/Homepage/SideNav'
import Loader from './Components/Blog/Loader'

const Blog = lazy(() => import('./Components/Blog/Blog'))

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <SideNav />
        <Route component={HomePage} exact path="/" />
        <Suspense
          fallback={
            <>
              <Loader />
              <h2
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#ec254c',
                }}
              >
                Loading Blogs...
              </h2>
            </>
          }
        >
          <Route component={Blog} exact path="/Blog" />
        </Suspense>
        <Route component={BlogDetails} path="/Blog/:id" />
        <Route component={Careers} exact path="/Careers" />
        <Route component={JobTitle} path="/Careers/:id" />
        <Route component={Resources} exact path="/Resources" />
        <Route component={TermsConditions} exact path="/TermsConditions" />
        <Route component={PrivacyPolicy} exact path="/PrivacyPolicy" />
        <Route component={Portfolio} exact path="/Portfolio" />
        <Route component={TechStack} exact path="/TechStack" />
        <Route component={ValuePropositon} exact path="/ValueProposition" />
        <Route component={ServiceDelivery} exact path="/ServiceDelivery" />
        <Route component={Testimonials} exact path="/Testimonials" />
        <Route component={ExecutiveSummary} exact path="/ExecutiveSummary" />
        <Route component={Project} exact path="/Project/:id" />
      </div>
    </Router>
  )
}

export default App
