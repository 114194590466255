import React from 'react'
import '../Homepage/Homepage.css'
import ContactForm from './ContactForm'

function Contact() {
  return (
    <div className="bg-col">
      <div id="Contact" className="uk-container">
        <h3 className="address texts">Contact Us</h3>
        <hr className="uk-divider-small active"></hr>
        <div className="uk-grid ">
          <div className="uk-width-1-3@s">
            <a className="loc-address" target="_blank" href="https://maps.google.com/maps?q=5.66660213470459%2C-0.19484350085258484&z=17&hl=en">
              <p className="texts">
                Address
                <br /> Hse # 4, Jasmine St. <br />
                Adjacent WW HOMES, Haasto
              </p>
            </a>

            <p className="texts">
              <a className="loc-address" target="_blank" href="tel://+233 303 937 079">+233 303 937 079</a> <br />
              <a className="loc-address" target="_blank" href="mailto://info@acsint.io">info@acsint.io</a> <br />
              Sales: <a className="loc-address" target="_blank" href="tel://+233 595 950 105">+233 595 950 105.</a> <br />
            </p>
            <h4 className="texts uk-margin-remove-bottom">Open hours</h4>
            <table className="uk-table uk-table-small texts uk-margin-small-top">
              <tbody>
                <tr>
                  <td className='uk-padding-remove'>
                    <b>Mon to Fri</b>
                  </td>
                  <td className='uk-padding-remove'>8AM - 5PM</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="uk-width-2-3@s">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
