const dummy = {
  careers: [
    {
      careerId: 1,
      careerTitle: 'Android Developer',
      careerContent:
        'Experienced, Skilled and Passionate Android Engineers needed to build high quality solutions for business customers using Kotlin, Java and other related technologies.',
      careerImg: '/images/Full_Stack.svg',
      careerDetailsPassage:
        'We are an organization that delights our customers by eliminating their pain points regarding information management with solutions powered by the best in kind processes, technologies, talent, and attitudes whilst remaining a socially responsible, profitable, and enduring corporate citizen. We are looking for an experienced Android developer who will work on modernizing, maintaining, and/or extending a code base of Native Android applications. You will also be responsible for reducing complexity, maintaining unit tests, creating integrations, and/or adding new features. Daily tasks will include algorithm development; system-level problem solving; writing high-quality, non-duplicated, and documented code; database schema design.  You will create pixel-perfect implementations of mock-ups provided by our designers.',
      careerSpecifications: (
        <div>
          <h4>Responsibilities</h4>
          <ul>
            <li>Translate designs and wireframes into high quality code </li>
            <li>
              Write quality code and maintain responsiveness of the application
            </li>
            <li>Identify bottlenecks and fix bugs </li>
            <li>
              Improving accuracy by developing automatic unit tests and
              integration tests
            </li>
            <li>
              Architecture direction of the solution from local database storage
              to front end
            </li>
          </ul>
          <h4>Technical Skills </h4>
          <ul>
            <li>
              Strong design skills applying Android frameworks, data structure,
              algorithm knowledge
            </li>
            <li>Proficiency in Kotlin and or Java </li>
            <li>
              Experience using persistence solutions like Room, Realm.io,
              Cupboard etc.
            </li>
            <li>Writing Unit and UI Tests </li>
            <li>Experience with git revision control </li>
            <li>
              Ability to measure and tune application performance using Android
              Studio Profiler
            </li>
            <li>
              Knowledge of Android networking using Retrofit and coroutines
            </li>
            <li>Strong work ethic</li>
            <li>Experience with Agile Software Development</li>
          </ul>
          <h4>Soft Skills</h4>
          <ul>
            <li>A good team player </li>
            <li>
              Passionate about technology and its application to problem solving
            </li>
            <li>
              Curious person who is always learning and improving themselves
            </li>
            <li>High integrity and principled person </li>
          </ul>
        </div>
      ),
    },
    {
      careerId: 2,
      careerTitle: 'UX Designer',
      careerContent:
        'We are looking for a UI/UX Designer to turn our software into easy-to-use products for our clients.',
      careerImg: '/images/Designer.svg',
      careerDetailsPassage:
        'UI/UX Designer responsibilities include gathering user requirements, designing graphic elements and building navigation components. To be successful in this role, you should have experience with design software and wireframe tools. If you also have a portfolio of professional design projects that includes work with web/mobile applications, we’d like to meet you. Ultimately, you’ll create both functional and appealing features that address our clients’ needs and help us grow our customer base.',
    },

    {
      careerId: 11,
      careerTitle: 'Full Stack Developer',
      careerContent:
        'Start your stellar career in software engineering, IT operations, sales, customer service and more by undertaking your National Service with us.',
      careerImg: '/images/Full-stack.svg',
      careerDetailsPassage:
        'We are an organization that delights our customers by eliminating their pain points regarding information management with solutions powered by the best in kind processes, technologies, talent and attitudes whilst remaining a socially responsible, profitable and enduring corporate citizen. We are looking for an experienced IOS developer who will work on modernizing, maintaining, and/or extending a code base of Native iOS applications. You will also be responsible for reducing complexity, maintaining unit tests, creating integrations, and/or adding new features. Daily tasks will include algorithm development; system-level problem solving; writing high-quality, non-duplicated, and documented code; database schema design. You will create pixel-perfect implementations of mock-ups provided by our designers.',
      careerSpecifications: (
        <div>
          <ul>
            <li>
              We are looking for an experienced developer who will work on
              modernizing, maintaining, and/or extending a code base of modern
              web applications and web APIs.
            </li>
            <li>
              Knowledge, Skills, and Attributes
              <ol>
                <li>
                  Proficient understanding of web verbs, JSON and XML protocols,
                  style sheets, and other web techniques
                </li>
                <li>
                  Proficiency in C# or Java with their related tools and
                  technologies: .NET4.5+, .NetCore and .Net5.0+; or Java Spring
                  (MVC, Boot), and Java Enterprise Editions
                </li>
                <li>
                  Writing tests unit tests with standard frameworks like NUnit|
                  Junit | XUnit
                </li>
                <li>
                  Proficient in any modern relational database management system
                  such as (PostgreSQL, MSSQL, MySQL or Oracle).
                  Skillsindeveloping against documentdatabasesdesirable
                </li>
                <li>
                  Demonstrable experience with modern application practices like
                  source control, DevOps, Test-driven development
                </li>
                <li>Ateamplayerwithtenacious problem-solving skills</li>
              </ol>
            </li>
            <li>This is a Full-time remote-work position</li>
            <li>CV with portfolio to jobs@acsint.io</li>
          </ul>
        </div>
      ),
    },

    {
      careerId: 4,
      careerTitle: 'Asp.Net Developer',
      careerContent:
        'We are an organization that delights our customers by eliminating their pain points regarding information management with solutions powered by the best in kind processes, technologies, talent and attitudes whilst remaining a socially responsible, profitable and enduring corporate citizen.',
      careerImg: '/images/Graphic_Designer.svg',
      careerDetailsPassage:
        'We are looking for an experienced ASP.NET developer who will work on modernizing, maintaining, and/or extending a code base of modern Asp.Net Web applications and web APIs. You will also be responsible for reducing complexity, maintaining unit tests, creating integrations, and/or adding new features. Daily tasks will include algorithm development; system-level problem solving; writing high-quality, non-duplicated, and documented code; database schema design; and implementing complex controls such as tabbed interfaces. You will create pixel-perfect implementations of mock-ups provided by our designers.',
    },
    {
      careerId: 5,
      careerTitle: 'Marketing Officer',
      careerContent:
        'We’re looking for a results-driven sales representative to actively seek out and engage customer prospects.',
      careerImg: '/images/Marketing.svg',
      careerDetailsPassage:
        'Selling products and services using solid arguments to prospective customers and performing cost-benefit analyses of existing and potential customers. Maintaining positive business relationships to ensure future sales and present, promote and sell products/services.',
    },

    {
      careerId: 6,
      careerTitle: 'iOS Developer',
      careerContent:
        'Experienced, Skilled and Passionate iOS Engineers needed to build high quality solutions for business customers using Swift, Xcode and other related technologies.',
      careerImg: '/images/iOS-developer.svg',
      careerDetailsPassage:
        'We are an organization that delights our customers by eliminating their pain points regarding information management with solutions powered by the best in kind processes, technologies, talent and attitudes whilst remaining a socially responsible, profitable and enduring corporate citizen. We are looking for an experienced IOS developer who will work on modernizing, maintaining, and/or extending a code base of Native iOS applications. You will also be responsible for reducing complexity, maintaining unit tests, creating integrations, and/or adding new features. Daily tasks will include algorithm development; system-level problem solving; writing high-quality, non-duplicated, and documented code; database schema design. You will create pixel-perfect implementations of mock-ups provided by our designers.',
      careerSpecifications: (
        <div>
          <h4>Responsibilities</h4>
          <ul>
            <li>Translate designs and wireframes into high quality code </li>
            <li>
              Write quality code and maintain responsiveness of the application.
            </li>
            <li>Identify bottlenecks and fix bugs </li>
            <li>
              Improving accuracy by developing automatic unit tests and
              integration tests
            </li>
            <li>
              Architecture direction of the solution from local database storage
              to front end
            </li>
            <li>Experience using Cocoa Pods and or swift package manager </li>
            <li>Writing Unit test and UI Tests</li>
            <li>Experience with git revision control </li>
            <li>Experience with threads and performance tuning </li>
            <li>Strong work ethic </li>
            <li>Hands on experience with Core Data and or Realm.io </li>
            <li>Experience with Agile Software Development  </li>
          </ul>
          <h4>Skills</h4>
          <ul>
            <li>Proficient understanding of the SOLID principles </li>
            <li>Proficiency in swift 4 or above </li>
          </ul>
        </div>
      ),
    },
    {
      careerId: 7,
      careerTitle: 'National Service and Internship Opportunites',
      careerContent:
        'Start your stellar career in software engineering, IT operations, sales, customer service and more by undertaking your National Service with us.',
      careerImg: '/images/NSS-personnel.svg',
      careerDetailsPassage:
        'We are an organization that delights our customers by eliminating their pain points regarding information management with solutions powered by the best in kind processes, technologies, talent and attitudes whilst remaining a socially responsible, profitable and enduring corporate citizen. We are looking for an experienced IOS developer who will work on modernizing, maintaining, and/or extending a code base of Native iOS applications. You will also be responsible for reducing complexity, maintaining unit tests, creating integrations, and/or adding new features. Daily tasks will include algorithm development; system-level problem solving; writing high-quality, non-duplicated, and documented code; database schema design. You will create pixel-perfect implementations of mock-ups provided by our designers.',
    },
    {
      careerId: 3,
      careerTitle: 'Applications Support Administrator',
      careerContent:
        'We need the services of a skilled applications support administrator for full time employment.',
      careerImg: '/images/Web_Developer.svg',
      careerDetailsPassage:
        'The ideal candidate should have extensive skills and knowledge in supporting applications, databases and systems operating in Linux and Windows environments.',
    },

    {
      careerId: 10,
      careerTitle: 'Software Development Manager',
      careerContent:
        'Start your stellar career in software engineering, IT operations, sales, customer service and more by undertaking your National Service with us.',
      careerImg: '/images/manager.svg',
      careerDetailsPassage:
        'A fast-growing company needs the services of a skilled database administrator for full time employment in Accra. The ideal candidate should have extensive skills and knowledge in database and systems administration in Linux and Windows environments. A minimum of Two Years’ experience in a similar role is required. A Diploma or Professional Qualification, preferably a DBA certification. ',
      careerSpecifications: (
        <div>
          <ul>
            <li>
              The service of a senior software developer with project management
              experience is needed to manage a dynamic team of developers
              building mobile, web and enterprise computer application software.
            </li>
            <li>
              The ideal candidate will liaise with stakeholders to determine
              requirements for software applications to be built, lead the
              analysis and design of solutions, manage the entire software
              development processes and develop the capabilities of the team
              members.
              <ol>
                <li>4+ years Experience</li>
                <li>
                  At least a Diploma or Professional Qualification in a software
                  development or related subject
                </li>
                <li>
                  Extensive experience of not less than 2 years in leading
                  software development teams
                </li>
                <li>
                  At least four years’ experience as a developer building web,
                  mobile and enterprise software
                </li>
                <li>
                  Knowledge and experience in the software development lifecycle
                </li>
                <li>Good communication and teamwork skills</li>
                <li>
                  Experience with at least one relational database management
                  system
                </li>
              </ol>
            </li>

            <li>CV with portfolio to jobs@acsint.io</li>
          </ul>
        </div>
      ),
    },
    {
      careerId: 8,
      careerTitle: 'Scrum Master',
      careerContent:
        'Responsible for monitoring the scrum processes and scrum meetings, increase in team efficiency, motivating the team; spins, argues for changes that will ensure quality and timeliness. Ensure the DoD (Definition of done) is being followed.',
      careerImg: '/images/Scrum-master.svg',
      careerDetailsPassage:
        'The ideal candidate shall be responsible for monitoring the scrum processes and scrum meetings, facilitate increase in team efficiency, motivate the team; spin and argues for changes that will ensure quality and timeliness. He/she shall ensure the DoD (Definition of done) is being followed.',
      careerSpecifications: (
        <div>
          <h4>Job Functions</h4>
          <ul>
            <li>
              Enabling teams to self-organize, self-manage and deliver via
              effective Agile practices
            </li>
            <li>
              Support and enforce the team rules on topics like quality, Kanban
              WIP etc.
            </li>
            <li>
              Active leading and supporting relentless improvement of the way
              the team performs its activities.
            </li>
            <li>
              Facilitate meetings like Daily Stand-Up, Iteration Review and
              Iteration Retrospective.
            </li>
            <li>
              Engage & proactively coach Product Owners to constantly refine the
              product backlog, with a focus on ensuring that backlog
              items/requirements are ready for planning into a future sprint.
            </li>
            <li>
              Facilitate daily team synchronization stand-ups, Sprint Planning
              sessions, retrospectives and sprint reviews /delivery
              demos/show-and-tells.
            </li>
            <li>
              Heavily involved in team activation with guidance on structure,
              risk identification and mitigation strategies
            </li>
            <li>
              Design, develop and establish agile governance understanding the
              value of metrics such as estimates and actuals, burn down and
              velocity.
            </li>
            <li>
              Take proactive ownership of issues that could impact delivery of
              team commitments, reaching out to the wider Head of Software
              Delivery, IT community, Product Owners or other stakeholders to
              facilitate impediment removal.
            </li>
            <li>
              Ensure that dependencies with other teams across the organization
              or with third parties are highlighted during sprint planning
              sessions and managed accordingly.
            </li>
            <li>
              Work closely with the Product Owners and team leads to develop a
              continuous improvement roadmap
            </li>
          </ul>
          ,<h4>Must Have Attrubutes</h4>
          <ul>
            <li>
              3+ years working experience with frameworks under Agile (Scrum &
              Kanban).
            </li>
            <li>
              Excellent communication skills in English, verbal and written.
            </li>
            <li>Professional certification in agile (PSM 1). </li>
            <li>Experience with Agile tools (Azure DevOps or Jira).</li>
            <li>Knowledge of software development cycle. </li>
          </ul>
        </div>
      ),
    },
    {
      careerId: 9,
      careerTitle: 'Database Administrator',
      careerContent:
        'A fast-growing company needs the services of a skilled database administrator for full time employment in Accra. The ideal candidate should have extensive skills and knowledge in database and systems administration in Linux and Windows environments. ',
      careerImg: '/images/database.svg',
      careerDetailsPassage:
        'A minimum of Two Years’ experience in a similar role is required. A Diploma or Professional Qualification, preferably a DBA certification. ',
      careerSpecifications: (
        <div>
          <h4>Qualification</h4>
          <ul>
            <li>
              Able to install, configure, operate and maintain PostgreSQL
              databases in Linux and Windows environments
            </li>
            <li>
              Collaborate closely with the development teams on performance
              tuning applications, queries and other data access components
            </li>
            <li>
              Able to install, configure, operate and maintain MongoDB databases
              in Linux and Windows environments
            </li>
            <li>
              Should be able to script fairly complex logic in bash shell
              scripts
            </li>
            <li>
              Knowledge of windows server system administration processes and
              procedures required
            </li>
            <li>
              Knowledge of Linux server (Ubuntu and CentOS) system
              administration processes and procedures required
            </li>
            <li>Must be a good team player with fast learning abilities</li>
          </ul>
        </div>
      ),
    },
  ],
  projects: [
    {
      image: '/images/Desktop1.png',
      name: 'Adaptive HRIS',
      description: 'HR and Payroll system',
    },
    {
      image: '/images/AB_1.png',
      name: 'Afro Barometer Time Tracker',
      description: 'Employee time tracking platform',
    },
    {
      image: '/images/fttx.svg',
      name: 'FTTX Platform',
      description:
        'FTTX platform for the broadband accounts setup & management using Web Application, USSD, SMS and Smart device apps',
    },
    {
      image: '/images/telecom.svg',
      name: 'Telecom Data & Social Bundle Packages',
      description:
        'Mobile Application & USSD Platform for Subscriptions, Social Community third party vendor integrations and other Youthful Subscriber Engagement functions.',
    },
    {
      image: '/images/telecom-vas.svg',
      name: 'Telecom VAS',
      description:
        'USSD, SMS, IVR &. Web platforms for data/voice/sms bundles and other telecom Value Added Services (VAS) products for Telcos. ',
    },
    {
      image: '/images/3pp-api.svg',
      name: "3rd Party API's",
      description:
        '3rd Party API developed using micro services architecture for banks and other integrators to build upon the functionality available in our developed solutions for ATMs, Payment apps and more.',
    },
  ],
}

export default dummy
