import env from "./env.config";
const options = {
    development: {
        apiBaseUrl: 'https://dev-blogapi.acsghana.com'
    },
    production: {
        apiBaseUrl: 'https://prod-blogapi.acsint.io'
    },
    staging: {
        apiBaseUrl: 'https://dev-blogapi.acsghana.com'
    },
}

const config = options[env];
export default config;