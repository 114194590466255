import React from 'react'
import './Blog.css'
function BlogPost({
  image = '',

  title = '',
  description = '',
}) {
  const trim = (str) => {
    if (str === null || str === '') return false
    else str = str.toString()

    str = str.replace(/(<([^>]+)>)/gi, '')
    return str.substring(0, Math.min(200, str.length))
  }

  return (
    <div
      data-testid="blog-post-1"
      className="uk-card uk-card-default cards blog-cards "
    >
      <h3 className="uk-card-media-top">
        <img className="port-img" src={image} />
      </h3>
      <div className="uk-card-body texts blog-text">
        <h3>{title}</h3>

        <p className="description-texts">{trim(description)}</p>
      </div>
    </div>
  )
}

export default BlogPost
