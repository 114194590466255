function Slideshow() {
    return (
        <div className="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow="min-height: 400; max-height: 400 ">

            <ul className="uk-slideshow-items">
                <li>
                    <img src="/images/banner01.jpg" alt="" data-uk-cover="" />
                    <div className="uk-overlay-primary slider-overlay uk-position-cover"></div>
                    <div className="uk-overlay  uk-position-bottom uk-text-left" data-uk-slideshow-parallax="x: 100,-100">
                        <div className="uk-container uk-container-fluid">
                            <h2 className="uk-margin-remove">Adaptive Computer Solutions</h2>
                            <p className="uk-margin-remove">
                                <div className="sub-title">
                                    <div><span className="entities">&lt;</span>We code dreams:</div>
                                    <div> it's possible<span className="entities">/&gt;</span></div>
                                </div>

                            </p>
                        </div>

                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Slideshow;