import React from 'react'
import './Careers.css'

function CareersCard({
  careerCardImage = '',
  careerCardTitle = '',
  careerCardPassage = '',
  careerCardPassageMain = '',
}) {
  return (
    <div
      data-testid="careers-card-1"
      className="uk-card uk-card-default uk-card-body careers-cards uk-margin-top"
    >
      <div className="uk-text-center">
        <img src={careerCardImage} />
      </div>
      <h4>
        <b>{careerCardTitle}</b>
      </h4>
      <p className="career-card-passage">
        <b>{careerCardPassage}</b>
      </p>
      <button class="uk-button uk-button-small uk-align-left card-button uk-margin-top">
        Read More
      </button>
    </div>
  )
}

export default CareersCard
