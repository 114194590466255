import React from 'react'

function PnSHeader({ pnsHeader = 'Product & Services' }) {
  return (
    <div data-testid="PnSHeader-1">
      <div className="uk-width-1-1">
        <h3 className="texts">{pnsHeader}</h3>
        <hr className="uk-divider-small active"></hr>
      </div>
    </div>
  )
}

export default PnSHeader
