import React from 'react'

function Loader() {
  return (
    <div>
      <img src="./assets/images/blog/loading.gif" />
    </div>
  )
}
export default Loader
