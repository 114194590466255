import React, { useState, useEffect } from 'react'
import UIkit from 'uikit'
import config from '../../config'

function ContactForm() {
  const initialValues = { name: '', email: '', subject: '', message: '' }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [formLoading, setFormLoading] = useState(false)
  const [formMessage, setFormMessage] = useState()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    var errors = validate(formValues)
    setFormErrors(errors)
    if (Object.keys(errors).length === 0) {
      sendFormRequest()
    }
  }

  const sendFormRequest = () => {
    setFormLoading(true)
    setFormMessage(null)
    const payload = {
      fullname: formValues.name,
      emailAddress: formValues.email,
      subject: formValues.subject,
      messageBody: formValues.message,
    }

    fetch(config.apiBaseUrl + '/api/contact/SubmitContactForm', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        setFormErrors({})
        setFormValues(initialValues)
        setFormLoading(false)
        setFormMessage(
          successMessage(
            'Thank you for getting in touch. Our team will get in touch shortly, but in the mean time checkout our portfolio and services',
          ),
        )
      })
      .catch((err) => {
        setFormMessage(
          errorMessage('An error occured. Please try again later.'),
        )
        setFormErrors({})
        setFormLoading(false)
      })
  }

  const errorMessage = (mssage) => {
    return (
      <div
        className="uk-animation-toggle uk-animation-slide-bottom-small uk-alert-danger custom-alert"
        data-uk-alert
      >
        <a className="uk-alert-close" data-uk-close></a>
        <p>{mssage}</p>
      </div>
    )
  }

  const successMessage = (mssage) => {
    return (
      <div
        className="uk-animation-toggle uk-animation-slide-bottom-small uk-alert-success custom-alert"
        data-uk-alert
      >
        <a className="uk-alert-close" data-uk-close></a>
        <p>{mssage}</p>
      </div>
    )
  }

  const validate = (values) => {
    const errors = {}
    const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    if (!values.name) {
      errors.name = errorMessage('Name field is required')
    } else if (values.name.length < 2) {
      errors.name = errorMessage("Name can't be 3 characters long")
    }
    if (!values.email) {
      errors.email = errorMessage('Email field is required')
    } 
    if (!values.subject) {
      errors.subject = errorMessage('Subject field is required')
    }
    if (!values.message) {
      errors.message = errorMessage('Message field is required')
    }
    return errors
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        data-testid="ContactForm-1"
        className="uk-grid uk-grid-small"
      >
        <div className="uk-width-1-1 uk-margin">{formMessage}</div>

        <div className=" uk-width-1-2@l">
          {formErrors.name}
          <input
            className="uk-input input-field uk-margin-bottom"
            type="text"
            name="name"
            placeholder="Name"
            value={formValues.name}
            onChange={handleChange}
            pattern="[a-zA-Z][a-zA-Z ]{2,}"
          />
        </div>

        <div className="uk-width-1-2@l ">
          {formErrors.email}
          <input
            className="uk-input input-field"
            type="email"
            name="email"
            placeholder="Email Address"
            value={formValues.email}
            onChange={handleChange}
          />
        </div>

        <div className="uk-margin uk-width-1-1">
          {formErrors.subject}
          <input
            className="uk-input input-field"
            type="text"
            name="subject"
            placeholder="Subject"
            value={formValues.subject}
            onChange={handleChange}
          />
        </div>

        <div className="uk-margin uk-width-1-1">
          {formErrors.message}
          <textarea
            className="uk-textarea message input-field"
            placeholder="Message"
            name="message"
            type="text"
            value={formValues.message}
            onChange={handleChange}
          />
        </div>
        <div className="uk-margin uk-text-left uk-width-1-1">
          <button
            className="uk-button uk-button-danger btn"
            {...(formLoading && { disabled: true })}
          >
            {formLoading ? (
              <>
                <span
                  className="uk-margin-small-right"
                  data-uk-spinner="ratio:.6"
                ></span>
                <span>Sending...</span>
              </>
            ) : (
              <span>Send</span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default ContactForm
