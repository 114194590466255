import React from 'react'
import './ClientTestimonials.css'
function TestimonialsCard({
  cardImage = '',
  cardTitle = '',
  cardPassage = '',
}) {
  return (
    <div
      data-testid="TestimonialsCardElement-1"
      className="uk-card uk-card-default uk-card-body cards "
    >
      <div className="uk-text-left">
        <img src={cardImage} />
      </div>
      <h4>
        <b>{cardTitle}</b>
      </h4>
      <p>{cardPassage}</p>
    </div>
  )
}

export default TestimonialsCard
