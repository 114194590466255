import "./ProductServiceCard.css";

function ProductServiceCard({
    image = '',
    title = '',
    content = '',
    index = '',
}) {
    const truncate = (text, length) => {
        return text.substring(0, Math.min(length, text.length))
    }
    return (
        <div className="uk-card uk-card-default product-service-card uk-card-body" data-uk-toggle={"target: #modal_" + index}>
            <div className="uk-text-left">
                <img className="img-logo" src={image} />
            </div>
            <h3 className="uk-card-title">{title}</h3>
            <p>{truncate(content, 78)}...</p>

            <div id={"modal_" + index} data-uk-modal="">
                <div className="uk-modal-dialog  products-services-modal">
                    <div className="uk-modal-body" data-uk-overflow-auto>
                        <div className="uk-text-left">
                            <img className="img-logo" src={image} />
                        </div>
                        <h2 className="uk-modal-title">{title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: content }}>

                        </p>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductServiceCard;