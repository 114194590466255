import React, { useState, useEffect } from 'react'
import PageTitle from '../../PageTitle/PageTitle'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'
import BlogTexts from './BlogTexts'
import { useLocation, useParams, Link } from 'react-router-dom'
import BlogPost from './BlogPost'
import BlogDetailsCard from './BlogDetailsCard'
import config from '../../config'
import { nameSlug } from '../../utlility'

function BlogDetails() {
  PageTitle('Blog Title :: ACS :: World Class Software')
  const path = useLocation()

  const { id } = useParams()

  const [blogs, setBlogs] = useState([])
  const [singleBlog, setSingleBlog] = useState({})

  const initValue = { name: '', email: '', message: '' }
  const [formValue, setFormValue] = useState(initValue)
  const [formError, setFormError] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(async () => {
    //get blogposts
    const blogPostUrl = config.apiBaseUrl + '/api/article/all'
    const blogData = await fetch(blogPostUrl)
    const convertedBlogData = await blogData.json()
    setBlogs(convertedBlogData)
    setSingleBlog(
      convertedBlogData.filter(
        (data) => nameSlug(data.articleTitle) == nameSlug(id),
      ),
    )
  }, [path.pathname])

  const handleChange = (e) => {
    console.log(e.target)
    const { name, value } = e.target
    setFormValue({ ...formValue, [name]: value })
    console.log(formValue)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validate(formValue))
    setIsSubmit(true)
  }

  useEffect(() => {
    console.log(formError)
    if (Object.keys(formError).length === 0 && isSubmit) {
      console.log(formValue)
    }
  }, [formError])

  const validate = (value) => {
    const error = {}
    const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    if (!value.name) {
      error.name = (
        <div className="uk-alert-danger" data-uk-alert>
          <a className="uk-alert-close" data-uk-close></a>
          <p>Name field is required </p>
        </div>
      )
    } else if (value.name.length < 3) {
      error.name = (
        <div className="uk-alert-danger" data-uk-alert>
          <a className="uk-alert-close" data-uk-close></a>
          <p>Name can't be 3 characters long</p>
        </div>
      )
    }
    if (!value.email) {
      error.email = (
        <div className="uk-alert-danger" data-uk-alert>
          <a className="uk-alert-close" data-uk-close></a>
          <p>Email field is required</p>
        </div>
      )
    } else if (!regex.test(value.email)) {
      error.email = (
        <div className="uk-alert-danger" data-uk-alert>
          <a className="uk-alert-close" data-uk-close></a>
          <p>This is a wrong email format</p>
        </div>
      )
    }
    if (!value.message) {
      error.message = (
        <div className="uk-alert-danger" data-uk-alert>
          <a className="uk-alert-close" data-uk-close></a>
          <p>Message field is required</p>
        </div>
      )
    }
    return error
  }

  return (
    <>
      <Navbar />

      <div data-testid="blog-details-1" className="bg-col">
        <div className="uk-container">
          <h3 className="address entities-1 texts">
            <b>{singleBlog[0]?.articleTitle}</b>
          </h3>
          <div className="uk-grid">
            <div className="uk-width-1-2@l uk-width-1-1@s">
              <h5 className="texts">
                <b>{singleBlog[0]?.departmentName}</b>
              </h5>
            </div>
            <div className="uk-width-1-2@l uk-width-1-1@s">
              <h5 className="uk-float-right">
                <b>
                  <span style={{ color: '#F31C4D' }}>Category</span>/Sept 12,
                  2021/By {singleBlog[0]?.authorFullName}
                </b>
              </h5>
            </div>
          </div>
          <br />

          <BlogDetailsCard
            blogBanner={singleBlog[0]?.articleBannerURL}
            blogPassage={singleBlog[0]?.articleContent}
          />

          <div className="uk-width-1-4@l uk-width-1-1@s">
            <h5 className="texts">
              <b>{singleBlog[0]?.departmentName}</b>
            </h5>
          </div>
          <BlogTexts />
          <div
            className="uk-width-1-4@l uk-width-1-1@s"
            style={{ marginTop: '150px' }}
          >
            <label className="label-texts">Leave A Comment</label>
          </div>
          {Object.keys(formError).length === 0 && isSubmit ? (
            <div className="uk-alert-success " data-uk-alert>
              <a className="uk-alert-close" data-uk-close></a>
              <h3>Thank you for submitting your message</h3>
              <p>
                We welcome fresh ideas and talents, don’t let it end here, go
                through our portfolio and services. Our Team will get you
                notified when you are shortlisted for a position.
              </p>
            </div>
          ) : (
            <div></div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="uk-grid">
              <div className="uk-width-1-4@l uk-margin-bottom uk-width-1-1@s">
                <p>{formError.name}</p>
                <input
                  className="uk-input name-input"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formValue.name}
                  onChange={handleChange}
                  pattern="[a-zA-Z][a-zA-Z ]{2,}"
                />
              </div>
              <div className="uk-width-1-4@l uk-width-1-1@s">
                <p>{formError.email}</p>
                <input
                  className="uk-input name-input"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formValue.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="uk-grid">
              <div className="uk-width-1-2@l uk-width-1-1@s">
                <div className="uk-margin">
                  <p>{formError.message}</p>
                  <textarea
                    className="uk-textarea comment"
                    rows="6"
                    type="text"
                    name="message"
                    placeholder="Message Here..."
                    value={formValue.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button className="uk-button Comment-btn uk-margin-large-bottom uk-button-danger">
                  Submit Comment
                </button>
              </div>
            </div>
          </form>

          <div
            style={{ justifyContent: 'space-between' }}
            className="uk-flex uk-grid"
          >
            {blogs
              .slice(0, 3)
              .map(
                (
                  { articleId, articleBannerURL, articleTitle, articleContent },
                  index,
                ) => (
                  <div
                    key={index}
                    className="uk-width-1-4@l uk-width-1-1@m uk-width-1-1@s uk-margin-bottom"
                  >
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`${path.pathname.slice(0, 6)}${articleTitle}`}
                    >
                      <BlogPost
                        path={path.pathname}
                        id={articleId}
                        image={articleBannerURL}
                        title={articleTitle}
                        description={articleContent}
                      />
                    </Link>
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BlogDetails
