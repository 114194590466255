import React from 'react'
import ClientTestimonialsHeader from './ClientTestimonialsHeader'
import TestimonialsCard from './TestimonialsCard'
import './ClientTestimonials.css'

function ClientTestimonials() {
  return (
    <div
      id="ClientsTestimonials"
      data-testid="ClientTestimonialsElement-1"
      className="bg-col"
    >
      <div className="uk-container uk-margin-large-top">
        <ClientTestimonialsHeader />
        <div
          className="uk-grid uk-grid-match uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-2@s"
          data-uk-scrollspy="target: > div; cls: uk-animation-fade-small; delay: 500"
        >
          <div data-uk-scrollspy-class="uk-animation-slide-top-small">
            <TestimonialsCard
              cardImage="/images/user4.png"
              cardTitle="Asiedu Samuel"
              cardPassage="O“ The level of sophistication of your solution, the short time within which it was developed and its impact on our bottom-line, is quite impressive"
            />
          </div>
          <div
            className="last-card"
            data-uk-scrollspy-class="uk-animation-slide-bottom-small"
          >
            <TestimonialsCard
              cardImage="/images/user1.png"
              cardTitle="Alfred Neequaye"
              cardPassage="“Your two developers assigned to us: A. and S. have been of tremendous help in the restoration of our customer’s trust in our bundles and other products and services.”"
            />
          </div>
          <div
            className="last-card"
            data-uk-scrollspy-class="uk-animation-slide-top-small"
          >
            <TestimonialsCard
              cardImage="/images/user2.png"
              cardTitle="Floxy Korkor Nartey"
              cardPassage="“The work output of your engineer E.O must be commended for its timeliness and quality”"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientTestimonials
