import React from 'react'
import dummy from '../../dummy';
import PageTitle from '../../PageTitle/PageTitle'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'

function Portfolio() {
  PageTitle('Portfolio :: ACS :: World Class Software');
  const nameSlug = (name) => {
    return name.replace(/[^a-zA-Z0-9]/g, '-').toLocaleLowerCase();
  }
  return (
    <>
      <div className="bg-col">
        <Navbar />
        <div className="uk-container">
          <h3 className="address texts">
            <b>Projects</b>
          </h3>
          <div
            className="uk-grid uk-grid-match uk-child-width-1-3@l uk-child-width-1-3@m uk-child-width-1-1@s"
            data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .uk-card; delay: 300; repeat: true"
          >
            {dummy.projects.map(({ name, description, image }, index) => {
              return (
                <div className=' uk-margin-bottom' key={index}>
                  <div className="uk-card uk-card-default uk-card-body cards"><a className='acs-link' href="#">
                    <h3 className="uk-card-title">
                      <img src={image} />
                    </h3>
                    <h4 className="texts">{name}</h4>
                    <p className="uk-text-light uk-text-truncate">{description}</p>
                  </a>
                  </div>
                </div>
              )
            })}
          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Portfolio
