import React from 'react'

function About() {
  return (
    <div data-testid="About-1" className="bg-col">
      <div className="uk-container">
        <div className="uk-grid">
          <div id="About" className="uk-width-1-3@l uk-width-1-1@s">
            <h3 className="address texts">About Us</h3>
            <hr className="uk-divider-small active"></hr>
          </div>
        </div>
        <p className="texts">
          As a reliable supplier of technical and project management talent on
          an outsourcing basis to enable corporate customers to achieve their
          projects that involve software development and integration components,
          we are proposing to provide a pool of highly skilled software
          engineering, agile scrum masters, software operations and support,
          quality assurance and other resources that may be necessary for
          businesses within Ghana and beyond to achieve their corporate goals.
          Our pool of resources have experience working in and leading agile
          teams, building and operating highly scalable applications from mobile
          frontend all the way to API and databases. The provided resources will
          work together with team members from the client to collaboratively
          deliver the stated goals of each project. In relation to the
          engagement model, our team has experience with handling projects
          delivered via the onshore, offshore, or hybrid outsourcing models.
          Modern application development and delivery practices will be observed
          including code reviews, unit testing, static code analysis,
          integration testing, DevOps and micro-services architecture.
        </p>
        <hr className="horizontal-line" />
        <div className="uk-grid">
          <div className="uk-width-1-3@l uk-width-1-1@s">
            <h3 className="texts" style={{ marginTop: '30px' }}>
              The Mission
            </h3>
            <hr className="uk-divider-small active"></hr>
            <p className="texts">
              We sustainably delight our customers with information solutions
              powered by the best in kind talent,processes, and technologies.
            </p>
          </div>
          <div className="uk-width-1-3@l uk-width-1-1@s">
            <h3 className="texts" style={{ marginTop: '30px' }}>
              The Vision
            </h3>
            <hr className="uk-divider-small active"></hr>
            <p className="texts">
              To become the best provider of enterprise information solutions in
              the world.
            </p>
          </div>

          <div className="uk-width-1-3@l uk-width-1-1@s">
            <h3 className="texts" style={{ marginTop: '30px' }}>
              The Values
            </h3>
            <hr className="uk-divider-small active"></hr>
            <p className="texts">
              Ingenious Collaborative Accountable Resilient Empathetic
            </p>
          </div>
        </div>
        <hr className="horizontal-line" />
      </div>
    </div>
  )
}

export default About
