import React from 'react'
import PageTitle from '../../PageTitle/PageTitle'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  PageTitle('Privacy Policy :: ACS :: World Class Software')
  return (
    <>
      <div data-testid="privacy-policy-1" className="bg-col">
        <Navbar />
        <div className="uk-container address">
          <h1 className="privacy-title">
            <b>Privacy Prolicy</b>
          </h1>
          <div className="uk-card uk-card-large card uk-card-default uk-card-body uk-margin-large-bottom">
            <p className="texts">
              We do not sell, trade or otherwise transfer to outside parties
              other than a third party working on behalf of ACS your personal
              identifiable information. Any of the information we collect from
              you may be used to:
            </p>
            <ul className="texts lists">
              <li className="privacy-listings">
                <span
                  className="check-icon uk-margin-small-right"
                  uk-icon="icon: check; ratio: 1"
                ></span>
                Personalize your experience (your information helps us to better
                respond to your needs)
              </li>
              <li className="privacy-listings">
                <span
                  className="check-icon uk-margin-small-right"
                  data-uk-icon="check"
                />
                Improve our customer service ( your information helps us to more
                effectively respond to your customer service
              </li>
              <li className="privacy-listings">
                <span
                  className="check-icon uk-margin-small-right"
                  data-uk-icon="check"
                />
                Periodically send promotional email about new products, special
                offers or other information which we think you may find
                interesting
              </li>
              <li className="privacy-listings">
                <span
                  className="check-icon uk-margin-small-right"
                  data-uk-icon="check"
                />
                Contact you for market research purposes.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
