import React from 'react'
import productService from '../../dummy/product_services'
import PnSHeader from './PnSHeader'
import PnSParagraph from './PnSParagraph'
import ProductServiceCard from './ProductServiceCard'

function ProductServices() {
  return (
    <div id="PnS" data-testid="PnSProductServices-1" className="bg-col">
      <div className="uk-container uk-margin-large-top">
        <PnSHeader />
        <PnSParagraph />

        <div
          className="uk-grid uk-grid-small uk-text-left uk-grid-match"
          data-uk-scrollspy="target: > div; cls: uk-animation-slide-top-small; delay: 500"
        >
          {productService.map(({ title, image, content }, index) => {
            return (
              <div
                key={index}
                className="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-4@l"
              >
                <ProductServiceCard
                  title={title}
                  image={image}
                  content={content}
                  index={index}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductServices
