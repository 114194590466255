import React from 'react'
function PnSParagraph({
  paragraph = 'Your business can improve our world. Therefore, let’s handle technology complexities in your brilliant ideas. This way you can concentrate on your core business.We believe in the potential of the African people and resources when it comes to technologysolutions to local, continental and global business and social challenges.We provide our customers with business applications development and integration services such as new solutions, customizations and integrations of line-of-business applications and development of packaged software. This frees up our clients to concentrate on their core business of making the continent and the world a better, safer and more equitable place for humankind.',
}) {
  return (
    <p data-testid="PnSParagraph-1" className="texts">
      {paragraph}
    </p>
  )
}

export default PnSParagraph
