import React from 'react'
import PageTitle from '../../PageTitle/PageTitle'
import Button from '../Homepage/Button'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'
import './Resources.css'

function Resources() {
  PageTitle('Resources :: ACS :: World Class Software"')
  return (
    <>
      <Navbar />
      <div data-testid="Resources-1" className="bg-col">
        <div className="uk-container address">
          <div className="uk-grid">
            <div className="uk-width-1-2@l uk-width-1-2@s">
              <h3 className="brochure-texts">Acs Brochure.pdf</h3>
            </div>
            <div className="uk-width-1-2@l uk-width-1-2@s">
              <a
                href="./Files/Acs Brochure.epub"
                download={'Acs Brochure.epub'}
              >
                <Button buttonText="Download" />
              </a>
            </div>
            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>
            <div className="uk-width-1-2@l uk-width-1-2@s uk-margin-top">
              <h3 className="brochure-texts">ACS BROCHURE.pdf</h3>
            </div>
            <div className="uk-width-1-2@l uk-width-1-2@s uk-margin-top">
              <a href="./Files/ACS BROCHURE.pdf" download={'ACS BROCHURE.pdf'}>
                <Button buttonText="Download" />
              </a>
            </div>
            <div className="uk-width-1-1 ">
              <hr />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Resources
