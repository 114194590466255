import React from 'react'

function Button({ buttonText = '' }) {
  return (
    <div data-testid="button-1">
      <button className={`uk-button uk-button-danger btn`}>{buttonText}</button>
    </div>
  )
}

export default Button
