import React from 'react'
import './Careers.css'

function CareersTexts({ mainHeader = '', mainParagraph = '', subHeader = '' }) {
  return (
    <div data-testid="careers-texts-1" className="uk-container">
      <h3 className="entities-1 address texts">
        <b>{mainHeader}</b>
      </h3>
      <p>
        <b>{mainParagraph}</b>
      </p>
      <h4>
        <b>{subHeader}</b>
      </h4>
    </div>
  )
}

export default CareersTexts
