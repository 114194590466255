import React from 'react'
import './ClientTestimonials.css'
function ClientTestimonialsHeader({ header = 'Client Testimonials' }) {
  return (
    <div data-testid="ClientTestimonialsHeaderElement-1">
      <div className="uk-width-1-1">
        <h3 className="texts">{header}</h3>
        <hr className="uk-divider-small active "></hr>
      </div>
    </div>
  )
}

export default ClientTestimonialsHeader
