import React from 'react'
import PageTitle from '../../PageTitle/PageTitle'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'
import './ValueProposition.css'

function ValuePropositon() {
  PageTitle('Value Proposition :: ACS :: World Class Software"')
  return (
    <>
      <div data-testid="ValueProposition-1" className="bg-col">
        <Navbar />
        <div className="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow="min-height: 300; max-height: 300 ">

          <ul className="uk-slideshow-items">
            <li>
              <img src="/images/BigOs.jpg" alt="" data-uk-cover="" />
              <div class="uk-overlay-primary slider-overlay uk-position-cover"></div>
              <div class="uk-overlay  uk-position-bottom uk-text-left" data-uk-slideshow-parallax="x: 100,-100">
                <div className="uk-container uk-container-fluid">
                  <h2 class="uk-margin-remove">With ACS</h2>
                  <p class="uk-margin-remove">
                    <div className="sub-title">
                      <div><span className="entities">&lt;</span>Change your technology talent</div>
                      <div>  overhead into variable cost<span className="entities">/&gt;</span></div>
                    </div>

                  </p>
                </div>

              </div>
            </li>
          </ul>
        </div>

        <div className="uk-container">
          <h3 className="entities texts address">
            <b>Value Proposition</b>
          </h3>
          <div className="uk-grid">
            <div className="uk-width-1-2 texts">
              <h4>Outsource</h4>
              <ul>
                <li>
                  Zero fixed cost of attracting and retaining rare talented
                  developers
                </li>
                <li>
                  Flexibility to burst or reduce technology talent based on
                  current demands from the market and firm’s strategic
                  initiatives
                </li>
                <li>
                  Pay as you go for development talent with no long-term
                  commitments
                </li>
                <li> High return on investment </li>
                <li>
                  Leverage on expertise from our work with multiple clients
                  across different industries
                </li>
              </ul>
            </div>
            <div className="uk-width-1-2 texts portfolio">
              <h4>In-house</h4>
              <ul>
                <li>
                  High cost of attracting, retaining and developing software
                  development talent
                </li>
                <li>
                  Engaged development staff overhead becomes fixed whether are
                  projects available or not
                </li>
                <li>Low return on investment</li>
                <li>
                  Inadequate expertise to keep up with rapidly changing trends
                  in the technological landscape
                </li>
                <li>
                  Saddled with long term costs related to people and
                  technologies
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ValuePropositon
