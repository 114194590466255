import React from "react";
import Footer from "../Homepage/Footer";
import Navbar from "../Homepage/Navbar";
import Step from "./Step";
import "./ServiceDelivery.css";
import PageTitle from "../../PageTitle/PageTitle";
function ServiceDelivery() {
  PageTitle("Service Delivery :: ACS :: World Class Software");
  return (
    <>
      <div className="bg-col">
        <Navbar />
        <div className="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow="min-height: 300; max-height: 300 ">

          <ul className="uk-slideshow-items">
            <li>
              <img src="/images/Service1.jpg" alt="" data-uk-cover="" />
              <div class="uk-overlay  uk-position-bottom uk-text-left" data-uk-slideshow-parallax="x: 100,-100">
                <div className="uk-container uk-container-fluid">
                  <h2 class="uk-margin-remove service-texts">Our Service Delivery Process</h2>
                </div>

              </div>
            </li>
          </ul>
        </div>


        <div className="uk-container">
          <div className="service-steps">
            <Step
              text="Customer Identifies Business Opportunities"
              step="1"
              direction="left"
            />
            <Step
              text="Customer launches project to exploit opportunity which may need software component"
              step="2"
              direction="right"
            />
            <Step
              text="ACS analyses software needs and comes out with estimated effort and allocates resources to meet business deadlines"
              step="3"
              direction="left"
            />
            {/* Fourth Section */}
            <Step
              text="Software is developed, tested and deployed to customer environment as part of business initiative"
              step="4"
              direction="right"
            />
            {/* Fifth Section */}
            <Step
              text="Customer Identifies Business Opportunities"
              step="5"
              direction="left"
            />
            <Step
              text="Customer successfully launches initiatives in time to exploit identified opportunity"
              step="6"
              direction="right"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ServiceDelivery;
