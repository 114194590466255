import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dummy from '../../dummy'
import PageTitle from '../../PageTitle/PageTitle'
import Footer from '../Homepage/Footer'
import Navbar from '../Homepage/Navbar'

import './Portfolio.css'

function Project() {
  const [project, setProject] = useState()
  const { id } = useParams();
  const nameSlug = (name) => {
    return name.replace(/[^a-zA-Z0-9]/g, '-').toLocaleLowerCase();
  }

  useEffect(() => {
    const projectSlug = nameSlug(id);
    const filter = dummy.projects.filter(e => nameSlug(e.name) == projectSlug);
    if (filter.length) {
      setProject(filter[0]);
    }
  });


  PageTitle('Portfolio-projects :: ACS :: World Class Software');

  return (
    <>
      {project ? (<div data-testid="project-1" className="bg-col">
        <Navbar />
        <div className="uk-container ">
          <h3 className="entities-1 texts address">
            <b>{project.name}</b>
          </h3>
          <p className="texts">
            {project.description}
          </p>
          <hr className="uk-margin-top horizontal-line" />
          <h5 className="texts uk-margin-top">
            <b>Desktop screens</b>
          </h5>
          <div
            className="uk-position-relative uk-visible-toggle uk-light"
            tabIndex="-1"
            data-uk-slider
          >
            <ul className="uk-slider-items uk-grid">
              <li className="uk-width-4-5@l">
                <div className="uk-panel">
                  <img src="/images/Screenshot.png" alt="" />
                  <p className="slider-texts">
                    Are you smart, driven and want to make a difference in the
                    world. You will fit right in. Send us your resume we will
                    contact you when a position is available
                  </p>
                </div>
              </li>
              <li className="uk-width-4-5@l">
                <div className="uk-panel">
                  <img src="images/Screenshot.png" alt="" />
                  <p className="slider-texts">
                    Are you smart, driven and want to make a difference in the
                    world. You will fit right in. Send us your resume we will
                    contact you when a position is available
                  </p>
                </div>
              </li>
              <li className="uk-width-4-5@l">
                <div className="uk-panel">
                  <img src="images/Screenshot.png" alt="" />
                  <p className="slider-texts">
                    Are you smart, driven and want to make a difference in the
                    world. You will fit right in. Send us your resume we will
                    contact you when a position is available
                  </p>
                </div>
              </li>
              <li className="uk-width-4-5@l">
                <div className="uk-panel">
                  <img src="images/Screenshot.png" alt="" />
                  <p className="slider-texts">
                    Are you smart, driven and want to make a difference in the
                    world. You will fit right in. Send us your resume we will
                    contact you when a position is available
                  </p>
                </div>
              </li>
              <li className="uk-width-4-5@l">
                <div className="uk-panel">
                  <img src="images/Screenshot.png" alt="" />
                  <p className="slider-texts">
                    Are you smart, driven and want to make a difference in the
                    world. You will fit right in. Send us your resume we will
                    contact you when a position is available
                  </p>
                </div>
              </li>
            </ul>
            <a
              className="uk-position-center-left uk-position-small arrow "
              href="#"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            ></a>

            <a
              className="uk-position-center-right arrow uk-position-small "
              href="#"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            ></a>
          </div>

          <hr className="horizontal-line" />

          <h5 className="texts address">
            <b>Mobile screens</b>
          </h5>
          <div
            className="uk-position-relative uk-visible-toggle uk-light portfolio"
            data-uk-slider
          >
            <ul className="uk-slider-items uk-grid uk-child-width-1-2 uk-child-width-1-3@m uk-grid">
              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                {' '}
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>

              <div className="uk-width-1-4@l uk-width-1-2@m uk-width-1-1@s">
                <li className="mtn-mobile">
                  <div className="uk-panel">
                    <img src="images/mtn_mobile.png" alt="" />
                    <div className="uk-container">
                      1
                      <p className="uk-container mobile-slider-texts">
                        Are you smart, driven and want to make a difference in
                        the world. You will fit right in.
                      </p>
                    </div>
                  </div>
                </li>
              </div>
            </ul>

            <a
              className="arrow uk-position-center-left uk-position-small "
              href="#"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            ></a>
            <a
              className="arrow uk-position-center-right uk-position-small "
              href="#"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            ></a>
          </div>
        </div>
      </div>
      ) : (
        <div className='uk-text-center'>
          <img src="images/404-error.png" alt="" />
        </div>
      )}

      <Footer />
    </>
  )
}

export default Project
