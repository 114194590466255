import React from "react";
import { NavLink } from "react-router-dom";

export default function SectionLink({
  base = "",
  section = "",
  activeClassName = "",
  ...rest
}) {
  function isBasePage() {
    const { origin, href } = window.location;
    let baseUrl = href.split("?")[0];
    return origin + (base.length ? base : "/") === baseUrl;
  }
  return isBasePage() ? (
    <NavLink {...rest} activeClassName={activeClassName} to={`#${section}`} />
  ) : (
    <a {...rest} href={`${base}/#${section}`}></a>
  );
}
