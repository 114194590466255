import React from 'react'
import DOMPurify from 'dompurify'

function BlogDetailsCard({ blogBanner = '', blogPassage = '' }) {
  //todo:
  // using blogUrl, filter blogs store to get blog data
  // pass blog data into blog banner and blog passage
  return (
    <>
      <div data-testid="blog-details-card-1">
        <img className="blog-img-resize" src={blogBanner} />
      </div>
      <div
        className="uk-margin-bottom"
        style={{ textAlign: 'justify' }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(blogPassage),
        }}
      ></div>
    </>
  )
}

export default BlogDetailsCard
