import React from 'react'
import About from './About'
import Banner from './Banner'
import Footer from './Footer'
import Navbar from './Navbar'
import OurClients from './OurClients'
import Contact from './Contact'
import PageTitle from '../../PageTitle/PageTitle'
import ClientTestimonials from '../ClientTestimonials/ClientTestimonials'
import ProductServices from '../ProductServices/ProductServices'
import Slideshow from './Slideshow'
function HomePage() {
  PageTitle('Homepage :: ACS :: World Class Software"')
  return (
    <>
      <Navbar />
      <Slideshow />
      <About />
      <ProductServices />
      <OurClients />
      <ClientTestimonials />
      <Contact />
      <Footer />
    </>
  )
}

export default HomePage
