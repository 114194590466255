import React from 'react'
import './Homepage.css'
import { NavLink, Link } from 'react-router-dom'
import SectionLink from '../SectionLink/SectionLink'

function Navbar() {
  return (
    <div data-testid="navbar-1" className="main-nav" data-uk-sticky>
      <nav data-uk-navbar className="uk-container uk-visible@m">
        <div
          className="uk-flex uk-flex-between navbar-Links"
          style={{ width: '100%' }}
        >
          <ul className="uk-navbar-nav nav-items">
            <li className="nav-item">
              <SectionLink section="About" activeClassName="active-link">
                About Us
              </SectionLink>
            </li>

            <li className="nav-item">
              <SectionLink section="PnS" activeClassName="active-link">
                Product & Services
              </SectionLink>
            </li>
            <li className="nav-item">
              <SectionLink section="OurClients" activeClassName="active-link">
                Client
              </SectionLink>
            </li>
            <li className="nav-item">
              <SectionLink section="Contact" activeClassName="active-link">
                Contact
              </SectionLink>
            </li>
          </ul>

          <div className="acs-logo">
            <Link to="/" className="uk-navbar-item uk-logo">
              <img
                src="/images/logo.png"
                className="nav-item"
                width="90"
                height="40"
              />
            </Link>
          </div>

          <ul className="uk-navbar-nav">
            <li className=" nav-item">
              <NavLink
                className={'Nav_link'}
                to="/Portfolio"
                activeClassName="active-link"
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={'Nav_link'}
                to="/Careers"
                activeClassName="active-link"
              >
                Career
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={'Nav_link'}
                to="/Blog"
                activeClassName="active-link"
              >
                Blog
              </NavLink>
            </li>
            {/* <hr className="uk-divider-small-vertical"></hr> */}
            <li className="nav-item vl">
              <a
                className="HRIS-link"
                target={'_blank'}
                href="https://www.adaptivehris.com/"
              >
                Adaptive HRIS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile menu */}
      <div className="uk-hidden@m mobile-nav">
        <div className="uk-grid">
          <div className="uk-width-1-2 ">
            <SectionLink
              section=""
              className="uk-navbar-item uk-logo uk-float-left"
            >
              <img src="/images/logo.png" className="logo" width="90" />
            </SectionLink>
          </div>
          <div className="uk-width-1-2">
            <a
              id="toggle-btn"
              className="nav-toggle uk-float-right uk-margin" data-uk-toggle="target: #side-navigation-offcanvas"
            >
              <span className="mobile-icon" data-uk-icon="menu"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
